
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { ServerType } from "prometheus-synced-ui";
import { DeveloperDetails, DeveloperItem, LITE_FEATURE } from "@/domain";

import { IDeveloperService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class DevelopersTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get developerService() {
    return this.container.resolve<IDeveloperService>(S.DEVELOPER_SERVICE);
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Equipments/DeveloperCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Equipments/DeveloperUpdate.vue");
  }

  get existingSerialNumbers() {
    return _.map(this.developers, (s) => s.serialNumber);
  }

  get headers() {
    return [
      {
        text: "Numéro de série",
        align: "center",
        sortable: true,
        value: "serialNumber",
      },
      {
        text: "Type",
        align: "center",
        sortable: true,
        value: "equipmentType",
      },
      {
        text: "Marque",
        align: "center",
        sortable: true,
        value: "equipmentBrand.name",
      },
      {
        text: "Numéro de PV",
        align: "center",
        sortable: true,
        value: "pvNumber",
      },
      {
        text: "Actions",
        align: "center",
        sortable: false,
        value: "actions",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  developers: DeveloperItem[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.developers = await this.developerService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(developer: DeveloperDetails) {
    this.developers.push(DeveloperItem.fromDetails(developer));
  }

  update(developer: DeveloperDetails) {
    const index = _.findIndex(this.developers, (d) => d.id == developer.id);
    if (index != -1) {
      this.developers.splice(index, 1, DeveloperItem.fromDetails(developer));
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const developer = _.find(this.developers, (d) => d.id == id)!;
      const result = await this.developerService.update(id, {
        serialNumber: developer.serialNumber,
        equipmentType: developer.equipmentType,
        equipmentBrandId: developer.equipmentBrand.id,
        pvNumber: developer.pvNumber,
        disabled: !developer.disabled,
      });

      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
